@import url('https://fonts.googleapis.com/css2?family=Marcellus&display=swap');

body {
  margin: 0;
  font-family: 'Marcellus', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Marcellus', serif;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.container {
  max-width: 1440px !important;
}
.container2 {
  max-width: 1000px !important;
}

:root {
  --title: 150px;
  --text: 24px;
  --text1: 16px;
  --text2: 32px;
  --subTitle: 64px;
  --blue: #3B60AD;
}

@media (max-width: 1440px) {
  :root {

    --title: 100px;
    --text: 24px;
    --text1: 16px;
    --text2: 32px;
    --subTitle: 55px;
  }
  .container {
    max-width: 1200px !important;
  }
}

@media (max-width: 768px) {
  
  :root {

    --title: 40px;
    --text: 18px;
    --text1: 13px;
    --text2: 22px;
    --subTitle: 30px;
  }
  .container2 {
    max-width: 80% !important;
  }
}