.input {
    background: none;
    border: none;
    color: #F2F2F2;
    border-bottom: 1px solid white;
    width: 100%;
    padding-bottom: 20px;

}
.input:focus {
    border: none;
    border-bottom: 1px solid white;
    outline: none;
}


::placeholder {
    color: #F2F2F2;
    font-size: var(--text);
}
.contact_info{
    text-align-last:justify;
}

@media (max-width: 768px) {
    .contact_info {
        text-align-last: start ;
    }
}