.Eco:hover {
    font-weight: bolder;
}

.Eco:hover #Path {
    stroke-width: 3px !important;
}

.Card:hover {
    background-color: rgb(59, 96, 173);
    color: white !important;
}

.Card:hover svg{
   fill: white !important;
   outline: white;
}